@import 'styles/reset.css';
@import 'styles/trainsition.css';
@import 'styles/nprogress.css';

@import '../node_modules/slick-carousel/slick/slick.css';
@import '../node_modules/slick-carousel/slick/slick-theme.css';

* {
  font-family: 'Pretendard', 'sans-serif' !important;
  font-display: swap;
}

/**
Use a better box model (opinionated). from tailWind
*/
*,
::before,
::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-tap-highlight-color: transparent;

  @media (min-width: 480px) {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

button:focus {
  outline: none;
}

div,
span,
header,
footer,
section {
  box-sizing: border-box;
}

select:focus {
  outline: none !important;
  box-shadow: none !important;
}

input,
select {
  border-color: #e5e7ec !important;
}

input:focus {
  outline: none !important;
  box-shadow: none !important;
}

input::placeholder {
  color: #828896 !important;
}

input[disabled] {
  -webkit-text-fill-color: rgba(130, 136, 150, 1) !important;
  -webkit-opacity: 1 !important;
  background: #f5f6fa !important;
  color: #828896 !important;
  opacity: 1 !important;
}

select[disabled] {
  background: #f5f6fa !important;
  color: #828896 !important;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}
abbr:where([title]) {
  text-decoration: underline dotted;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}
a {
  color: inherit;
  text-decoration: inherit;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp,
pre {
  font-family: theme(
    'fontFamily.mono',
    ui-monospace,
    SFMono-Regular,
    Menlo,
    Monaco,
    Consolas,
    'Liberation Mono',
    'Courier New',
    monospace
  );
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  border: 0;
}
button,
select {
  text-transform: none;
}
button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}
:-moz-focusring {
  outline: auto;
}
:-moz-ui-invalid {
  box-shadow: none;
}
progress {
  vertical-align: baseline;
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
summary {
  display: list-item;
}
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}
fieldset {
  margin: 0;
  padding: 0;
}
legend {
  padding: 0;
}
ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
textarea {
  resize: vertical;
}
input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: theme('colors.gray.400', #9ca3af);
}
button,
[role='button'] {
  cursor: pointer;
}
:disabled {
  cursor: default;
}
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}
img,
video {
  max-width: 100%;
  height: auto;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
[hidden] {
  display: none;
}

.container {
  width: 100%;
}
